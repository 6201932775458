var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    attrs: {
      "body-class": "p-0"
    }
  }, [_vm.loadingFetchAncillary ? _c('b-alert', {
    staticClass: "mt-1 px-2 py-1 d-flex-center gap-2 justify-content-start",
    attrs: {
      "show": "",
      "variant": "warning"
    }
  }, [_c('b-spinner', {
    attrs: {
      "variant": "warning",
      "tag": "span"
    }
  }), _c('span', [_vm._v(" " + _vm._s(_vm.$t('loading')) + " ")])], 1) : _vm.isEmpty(_vm.serviceOptions) ? _c('div', [_c('b-alert', {
    staticClass: "mt-1",
    attrs: {
      "show": "",
      "variant": "warning"
    }
  }, [_c('div', {
    staticClass: "alert-body text-center text-warning"
  }, [_c('feather-icon', {
    staticClass: "mr-25",
    attrs: {
      "icon": "FrownIcon",
      "size": "25"
    }
  }), _c('span', [_vm._v(" " + _vm._s(_vm.$t('flight.noAncillary')) + " ")])], 1)])], 1) : _c('b-card', {
    staticClass: "mb-0 border-0",
    attrs: {
      "body-class": "p-0"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "5"
    }
  }, [_c('b-form-group', {
    staticClass: "pb-25 mb-0",
    attrs: {
      "label": _vm.$t('flight.flightSegment'),
      "label-for": "chang-bay"
    }
  }, [_c('v-select', {
    staticClass: "select-size-sm mr-1 w-100",
    attrs: {
      "input-id": "chang-bay",
      "label": "segmentData",
      "clearable": false,
      "options": _vm.segmentOptions,
      "placeholder": _vm.$t('flight.placeholderSelect')
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(_ref) {
        var segmentData = _ref.segmentData;
        return [_c('p', {
          staticClass: "text-dark"
        }, [_vm._v(" " + _vm._s(_vm.$t('flight.airline')) + ": "), _c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v(" " + _vm._s(segmentData.source ? segmentData.source : segmentData.airline) + " ")])]), _c('p', {
          staticClass: "text-dark"
        }, [_vm._v(" " + _vm._s(_vm.$t('flight.flightSegment')) + ": "), _c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v(" " + _vm._s(segmentData.departure.IATACode) + "-" + _vm._s(segmentData.arrival.IATACode) + " (" + _vm._s(_vm.convertISODateTime(segmentData.departure.at).dayAndMonth) + ") ")])])];
      }
    }, {
      key: "selected-option",
      fn: function fn(_ref2) {
        var segmentData = _ref2.segmentData;
        return [_c('p', {
          staticClass: "font-small-4 text-dark mb-0"
        }, [_c('span', {
          staticClass: "font-weight-bolder"
        }, [_vm._v(" " + _vm._s(segmentData.source ? segmentData.source : segmentData.airline) + " ")]), _vm._v(" | "), _c('span', {
          staticClass: "font-weight-bolder"
        }, [_vm._v(" " + _vm._s("".concat(segmentData.departure.IATACode, " - ").concat(segmentData.arrival.IATACode)) + " (" + _vm._s(_vm.convertISODateTime(segmentData.departure.at).dayAndMonth) + ") ")])])];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.segment,
      callback: function callback($$v) {
        _vm.segment = $$v;
      },
      expression: "segment"
    }
  })], 1)], 1)], 1), _c('hr', {
    staticClass: "my-50"
  }), _vm.passengersData ? _c('div', _vm._l(_vm.passengersData, function (passenger, index) {
    return _c('b-row', {
      key: index,
      staticClass: "w-100 m-0"
    }, [_c('b-col', {
      staticClass: "px-0 mt-1 mt-md-0 pb-25 pb-md-1 pt-25",
      attrs: {
        "md": "4"
      }
    }, [_vm._v(" " + _vm._s(_vm.$t('flight.passenger')) + ": "), _c('span', {
      staticClass: "font-weight-bolder"
    }, [_vm._v(" " + _vm._s(passenger.firstName && passenger.lastName ? "".concat(passenger.title, " ").concat(passenger.lastName, " ").concat(passenger.firstName) : "".concat(_vm.$t("reservation.".concat(passenger.paxType)), " #").concat(index + 1)) + " ")])]), _c('b-col', {
      staticClass: "px-0",
      attrs: {
        "cols": "12",
        "md": "5"
      }
    }, [_c('v-select', {
      staticClass: "ssrs_package_select select-size-sm w-100",
      attrs: {
        "id": "service-packages-".concat(index),
        "label": "code",
        "filter-by": _vm.filterBy,
        "placeholder": _vm.$t('flight.servicePlaceholder'),
        "disabled": _vm.disableServicePackage,
        "options": _vm.serviceListOptions
      },
      on: {
        "input": function input(val) {
          return _vm.setDefaultPassengerAmount(val, passenger);
        }
      },
      scopedSlots: _vm._u([{
        key: "option",
        fn: function fn(data) {
          return [_c('div', {
            staticClass: "d-flex justify-content-between",
            class: _vm.isMobileView ? 'font-small-1' : 'font-small-4'
          }, [_c('div', [_c('span', {
            staticClass: "mr-75",
            class: _vm.resolveVariantByBagg(data)
          }, [_vm._v(" " + _vm._s(data.ssrName === 'ssrBags' ? _vm.resolveSsrBagsName(data) : _vm.resolveSsrNameByCode(data.code)) + " ")]), _c('span', {
            staticClass: "font-weight-bolder text-warning"
          }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.resolvePriceService(data, passenger))) + " ")])]), data.ssrName === 'ssrOthers' && data.segmentData && data.segmentData.source && ['TH'].includes(data.segmentData.source) && data.ssrValue ? _c('div', [_vm._v(" " + _vm._s(data.ssrValue) + " ")]) : _vm._e()])];
        }
      }, {
        key: "selected-option",
        fn: function fn(data) {
          return [_c('div', {
            class: "d-flex align-items-center font-weight-bolder ".concat(_vm.isMobileView ? 'font-small-1' : 'font-small-4')
          }, [_c('div', [_c('span', [_vm._v(" " + _vm._s(data.ssrName === 'ssrBags' ? _vm.resolveSsrBagsName(data) : _vm.resolveSsrNameByCode(data.code)) + " ")]), _vm._v(" ("), _c('span', {
            staticClass: "text-warning"
          }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.resolvePriceService(data, passenger))) + " ")]), _vm._v(") ")]), data.ssrName === 'ssrOthers' && data.segmentData && data.segmentData.source && ['TH'].includes(data.segmentData.source) && data.ssrValue ? _c('div', {
            staticClass: "ml-75"
          }, [_vm._v(" (" + _vm._s(data.ssrValue) + ") ")]) : _vm._e()])];
        }
      }], null, true),
      model: {
        value: passenger.servicePackage,
        callback: function callback($$v) {
          _vm.$set(passenger, "servicePackage", $$v);
        },
        expression: "passenger.servicePackage"
      }
    })], 1), _c('b-col', {
      staticClass: "mt-50 mt-md-0 px-0 px-md-1",
      attrs: {
        "cols": "4",
        "md": "2"
      }
    }, [_c('v-select', {
      staticClass: "select-size-sm w-100",
      attrs: {
        "id": "service-packages-amount-".concat(index),
        "label": "text",
        "placeholder": _vm.$t('flight.Amount'),
        "clearable": false,
        "disabled": _vm.disableServicePackage || _vm.segment && ['VN1A'].includes(_vm.segment.source) || !passenger.servicePackage,
        "options": _vm.amountOptions
      },
      scopedSlots: _vm._u([{
        key: "option",
        fn: function fn(_ref3) {
          var text = _ref3.text;
          return [_c('span', {
            staticClass: "font-small-3"
          }, [_vm._v(" " + _vm._s(text) + " " + _vm._s(_vm.$t('flight.package')) + " ")])];
        }
      }, {
        key: "selected-option",
        fn: function fn(_ref4) {
          var text = _ref4.text;
          return [_c('span', {
            staticClass: "font-small-3 pl-50"
          }, [_vm._v(" " + _vm._s(text) + " " + _vm._s(_vm.$t('flight.package')) + " ")])];
        }
      }], null, true),
      model: {
        value: passenger.serviceAmount,
        callback: function callback($$v) {
          _vm.$set(passenger, "serviceAmount", $$v);
        },
        expression: "passenger.serviceAmount"
      }
    })], 1)], 1);
  }), 1) : _vm._e(), !_vm.isHideFunction ? _c('div', {
    staticClass: "text-center mt-50"
  }, [_c('b-button', {
    staticClass: "mr-2",
    attrs: {
      "variant": "outline-warning"
    },
    on: {
      "click": _vm.handleClearForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('flight.clear')) + " ")]), _c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.handleAddServices
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('flight.addService')) + " ")])], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }